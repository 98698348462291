 <template>
	<el-dialog top="20vh" title="设置银行卡" width="500px" :visible.sync="this_is_show" @close="close">
        <el-form status-icon label-width="0px" label-position="left" style="margin-top:20px">
			<el-form-item>
				<el-input v-model="tel" placeholder="请输入收款人手机号"  auto-complete="new-password"  clearable>
					<el-button style="width:100px" slot="prepend">手机号</el-button>
					<el-button  @click="set_bank_card_info" type="success" slot="append" style="width:112px;background-color:#409EFF;color:black;border-radius: 0;">搜索</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="name" placeholder="姓名" auto-complete="new-password" >
					<el-button style="width:100px" slot="prepend">姓名</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="bank_cname" placeholder="银行名称" auto-complete="new-password" >
					<el-button style="width:100px" slot="prepend">银行名称</el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="card_num" placeholder="银行卡号" auto-complete="new-password">
					<el-button style="width:100px" slot="prepend">银行卡号</el-button>
				</el-input>
			</el-form-item>
			<div>
				<el-select class="el_input" v-model="is_owner" clearable style="width:460px;margin-bottom: 20px;" placeholder="绑卡关系">
					<el-option label="自己的卡" value="1"></el-option>
					<el-option label="他人的卡" value="2"></el-option>
				</el-select>
				<div v-if="is_owner==2">
					<el-form-item>
						<el-input v-model="card_user_name" placeholder="银行卡归属人姓名" auto-complete="new-password">
							<el-button style="width:165px" slot="prepend">银行卡归属人姓名</el-button>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="card_user_tel" placeholder="银行卡归属人手机号" auto-complete="new-password">
							<el-button style="width:165px" slot="prepend">银行卡归属人手机号</el-button>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="card_user_id_card_num" placeholder="银行卡归属人身份证号" auto-complete="new-password" >
							<el-button style="width:165px" slot="prepend">银行卡归属人身份证号</el-button>
						</el-input>
					</el-form-item>
					<el-select class="el_input" v-model="relation" clearable style="width:460px;margin-bottom: 20px;" placeholder="关系声明">
						<el-option label="收款人" value="收款人"></el-option>
						<el-option label="收款人" value="收款人"></el-option>
						<el-option label="经纪人" value="经纪人"></el-option>
						<el-option label="夫妻" value="夫妻"></el-option>
						<el-option label="子孙" value="子孙"></el-option>
						<el-option label="父母" value="父母"></el-option>
						<el-option label="其他血亲" value="其他血亲"></el-option>
					</el-select>
					<el-button style="width:200px"  @click="voucher_add_sub" slot="prepend">上传关系证明</el-button>
					<div>
						<img :src="src" style="width:300px;	margin: 10px 10px 20px 60px;">
					</div>
				</div>
				
			</div>
            <!-- <el-form-item>
				<el-input v-model="pwd2" placeholder="银行卡号" auto-complete="new-password" disabled>
					<el-button style="width:100px" slot="prepend">银行卡号</el-button>
				</el-input>
			</el-form-item> -->
            <el-form-item>
                <div class="button">
                     <!-- <el-button @click="dialogFormVisible=false">解除绑定</el-button> -->
                     <el-button type="primary" @click="sub">立即提交</el-button>
                </div>
               
           </el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import payPwdReset from '@/pages/user/act/pay_pwd.vue'
	import { Loading } from 'element-ui';
	import {mapState} from 'vuex'
	export default {
		props:{
			is_show:Number,
			user_num:String,
		},
		components:{
			payPwdReset
		},
		data() {
			return {
				bank_card_type:'',//是否可以输入
                tel:'',//手机
                name:'',//姓名
				card_num:'',//银行卡号
				bank_cname:'',//银行名称
				is_owner:'',//绑卡关系
				card_user_name:'',//归属人姓名
				card_user_id_card_num:'',//归属人身份证号
				relation:'',//关系声明
				relation_proof:'',//关系证明
				card_user_tel:'',//归属人手机号
				//页面是否显示
				this_is_show:false,
				src:'',//预览
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.this_is_show=false
				}else {
                    this.this_is_show=true
				}
			},
            data(new_data){
                 this.tord_list = new_data
            }
		},
		computed:{
			...mapState(["user_info"])
		},
		methods: {
			//提交
			sub(){
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'bankcard_bind_admin',
						user_num:this.user_num,
						card_num:this.card_num,
						is_owner:this.is_owner,
						card_user_name:this.card_user_name,
						card_user_id_card_num:this.card_user_id_card_num,
						relation:this.relation,
						relation_proof:this.relation_proof,
						card_user_tel:this.card_user_tel,
					},callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'添加成功'
						})
						//关闭弹窗
						this.this_is_show = false

						this.$emit('success')
					}
				})
			},
			voucher_add_sub(){//提交凭证

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {

						//预览
						this.src=res.src

						//上传
						this.$my.qiniu.upl({
							bucket: 'trucktordrealtimevoucher',
							file_path: res.src,
							callback: (data) => {
								var key = data.key.split('__v__1')
								this.relation_proof = key [0]
							}
						});
					}
				});
			},
            set_bank_card_info(){
                if(!this.tel){
                    this.$my.other.msg({
                        type:'info',
                        str:'请输入手机号'
                    })
                    return
                }
                //修改数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'bankcard_list_admin',
						app_user_tel:this.tel
					},callback:(data)=>{
						if(data.list.length==0){
							this.$my.other.msg({
								type:'info',
								str:'查询为空'
							})
							this.bank_card_type = false
							return
						}
						let bank_card_info = data.list[0]
						this.card_num = bank_card_info.card_num
						this.bank_cname = bank_card_info.bank_cname
						this.name = bank_card_info.card_user_name
					}
				})
            },
		   close(){
               this.tel = ''
               this.name = ''
               this.card_num = ''
               this.bank_cname = null

			   this.bank_card_type='',//是否可以输入
			   this.is_owner='',//绑卡关系
			   this.card_user_name='',//归属人姓名
			   this.card_user_id_card_num='',//归属人身份证号
			   this.relation='',//关系声明
			   this.relation_proof='',//关系证明
			   this.src=''//预览
           },
		}
	}
</script>
<style lang="scss" scoped>
    .operation{
		color: rgb(18, 208, 221);
		margin-left: 5px;
	}
	.el-dialog-s{
		z-index: 11;
	}
	.under_tab{
		position: relative;
		height: 40px;
		border: 1px solid #eee;
		border-width: 0 1px 1px 1px;
	}
	.this_pay_total{
		position: absolute;
		right: 0px;
		width: 539px;
		border-left: 1px solid #eee;
		height: 40px;
		line-height: 40px;
		padding-left: 10px;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:100%;
	}
	.payee{
		height: 30px;
		width: 30px;
		font-size: 20px;
		text-align: center;
		
		border-radius: 50%;
		color:rgb(0, 0, 0);
		.shou{
			margin-top: 4px;
		}
	}
	.data{
		margin-top: 5px;
		margin-left: 10px;
	}
	.red{
		background-color: red;
	}
	.green{
		background-color: #4CD964;
	}
    .button{
        text-align: center;
    }
</style>